import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeDownloadSkeleton } from "./TypeDownload";
import type { TypeProjectSkeleton } from "./TypeProject";
import type { TypeStaffSkeleton } from "./TypeStaff";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeEventFields = {
  title: EntryFieldTypes.Symbol;
  startdate?: EntryFieldTypes.Date;
  endDate?: EntryFieldTypes.Date;
  timezone?: EntryFieldTypes.Symbol<"AEST (UTC+10)" | "BST (UTC+1)" | "CDT (UTC-5)" | "CEST (UTC+2)" | "CET (UTC+1)" | "CST (UTC-6)" | "EDT (UTC-4)" | "EEST (UTC+3)" | "EET (UTC+2)" | "EST (UTC-5)" | "GMT (UTC+0)" | "IST (UTC+5.5)" | "JST (UTC+9)" | "MDT (UTC-6)" | "MST (UTC-7)" | "NZST (UTC+12)" | "PDT (UTC-7)" | "PST (UTC-8)">;
  location?: EntryFieldTypes.Symbol;
  addressText?: EntryFieldTypes.Symbol;
  eventType?: EntryFieldTypes.Symbol<"Celebration" | "Conference" | "Launch" | "Meeting" | "Panel Discussion" | "Roundtable" | "Webinar">;
  host?: EntryFieldTypes.Symbol;
  speaker?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeStaffSkeleton>>;
  attendee?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeStaffSkeleton>>;
  moderator?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeStaffSkeleton>>;
  button?: EntryFieldTypes.Object;
  video?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeDownloadSkeleton>>;
  project?: EntryFieldTypes.EntryLink<TypeProjectSkeleton>;
  themeOrTopic?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>>;
};

export type TypeEventSkeleton = EntrySkeletonType<TypeEventFields, "event">;
export type TypeEvent<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeEventSkeleton, Modifiers, Locales>;

export function isTypeEvent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeEvent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "event";
}
